<template>
  <q-card class="full-width" :style="{ maxWidth: '400px' }">
    <q-card-section>
      <h3 class="text-h6 q-mb-sm q-mt-none">Create Account</h3>

      <q-form ref="form" @submit.prevent="onSubmitForm">
        <q-input
          v-model.trim="form.name"
          :rules="[fieldRules.required]"
          outlined
          label="Account name"
          class="q-mb-md"
        />

        <q-select
          v-model="form.methodTypeId"
          :options="methodTypeItems"
          :loading="methodTypes.loading"
          :rules="[fieldRules.required]"
          :input-debounce="0"
          outlined
          use-input
          use-chips
          emit-value
          map-options
          option-label="name"
          option-value="id"
          label="Select Method"
          class="q-mb-md"
          @input-value="methodTypes.searchText = $event"
        />

        <q-select
          v-model="form.agentId"
          :options="agentItems"
          :loading="agents.loading"
          :rules="[fieldRules.required]"
          :input-debounce="0"
          :virtual-scroll-slice-size="5"
          outlined
          use-input
          use-chips
          emit-value
          map-options
          option-label="username"
          option-value="id"
          label="Select Agent"
          class="q-mb-md"
          @input-value="agents.searchText = $event"
        />

        <div class="row q-mt-md">
          <section class="col-6">
            <div class="q-pr-sm">
              <q-btn
                :disable="form.loading"
                no-caps
                unelevated
                outline
                type="button"
                color="red"
                label="Cancel"
                class="full-width"
                @click="onCancel"
              />
            </div>
          </section>

          <section class="col-6">
            <div class="q-pl-sm">
              <q-btn
                :loading="form.loading"
                :disable="disabledSubmitForm"
                no-caps
                unelevated
                type="submit"
                color="primary"
                label="Create"
                class="full-width"
              />
            </div>
          </section>
        </div>
      </q-form>
    </q-card-section>
  </q-card>
</template>

<script>
import { accountController } from '@/shared/api';

export default {
  data() {
    return {
      methodTypes: {
        list: [],
        loading: false,
        searchText: '',
      },
      agents: {
        list: [],
        loading: false,
        searchText: '',
      },
      form: {
        loading: false,
        name: '',
        methodTypeId: null,
        agentId: null,
      },
    };
  },
  computed: {
    disabledSubmitForm() {
      return this.methodTypes.loading || this.agents.loading;
    },
    methodTypeItems() {
      const searchText = this.methodTypes.searchText.trim().toLowerCase();

      if (searchText) {
        return Object.freeze(
          this.methodTypes.list.filter((item) =>
            item.name.toLowerCase().includes(searchText)
          )
        );
      }

      return this.methodTypes.list;
    },
    agentItems() {
      const searchText = this.agents.searchText.trim().toLowerCase();

      if (searchText) {
        return Object.freeze(
          this.agents.list.filter((item) =>
            item.username.toLowerCase().includes(searchText)
          )
        );
      }

      return this.agents.list;
    },
    fieldRules() {
      return {
        required: (value) =>
          !!value?.toString().trim().length || 'Please type value',
      };
    },
  },
  methods: {
    async fetchMethodTypes() {
      this.methodTypes.loading = true;

      try {
        const data = await accountController.fetchRefillAccountMethods();

        this.methodTypes.list = Object.freeze(data);
        this.methodTypes.loading = false;
      } catch (error) {
        this.methodTypes.loading = false;
      }
    },
    async fetchAgents() {
      this.agents.loading = true;

      try {
        const data = await accountController.fetchRefillAccountAgents();

        this.agents.list = Object.freeze(data);
        this.agents.loading = false;
      } catch (error) {
        this.agents.loading = false;
      }
    },
    async onSubmitForm() {
      if (this.form.loading) return;

      this.form.loading = true;

      const { name, methodTypeId, agentId } = this.form;

      try {
        await accountController.createRefillAccount({
          name,
          methodTypeId,
          agentId,
        });

        this.$emit('success');
        this.form.loading = false;
      } catch (error) {
        this.form.loading = false;
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
  mounted() {
    this.fetchMethodTypes();
    this.fetchAgents();
  },
};
</script>
